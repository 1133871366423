import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-body d-flex align-items-center pt-3 pb-0" }
const _hoisted_2 = { class: "d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2" }
const _hoisted_3 = {
  href: "#",
  class: "fw-bolder text-dark fs-4 mb-2 text-hover-primary"
}
const _hoisted_4 = { class: "fw-bold text-muted fs-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: [_ctx.widgetClasses, "card"]
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("a", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createVNode("span", _hoisted_4, _toDisplayString(_ctx.description), 1)
      ]),
      _createVNode("img", {
        src: _ctx.avatar,
        alt: "",
        class: "align-self-end h-100px"
      }, null, 8, ["src"])
    ])
  ], 2))
}