
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-widget-6",
  props: {
    widgetClasses: String,
    color: String,
    description: String,
    progress: String,
    title: String
  },
  components: {}
});
