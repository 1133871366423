import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-body my-3" }
const _hoisted_2 = { class: "py-1" }
const _hoisted_3 = { class: "text-dark fs-1 fw-bolder me-2" }
const _hoisted_4 = { class: "fw-bold text-muted fs-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: [[_ctx.widgetClasses, `bg-light-${_ctx.color}`], "card"]
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("a", {
        href: "#",
        class: [`text-${_ctx.color}`, "card-title fw-bolder fs-5 mb-3 d-block"]
      }, _toDisplayString(_ctx.description), 3),
      _createVNode("div", _hoisted_2, [
        _createVNode("span", _hoisted_3, _toDisplayString(_ctx.progress), 1),
        _createVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1)
      ]),
      _createVNode("div", {
        class: [`bg-${_ctx.color}`, "progress h-7px bg-opacity-50 mt-7"]
      }, [
        _createVNode("div", {
          class: [`bg-${_ctx.color}`, "progress-bar"],
          role: "progressbar",
          style: `width: ${_ctx.progress}`,
          "aria-valuenow": "50",
          "aria-valuemin": "0",
          "aria-valuemax": "100"
        }, null, 6)
      ], 2)
    ])
  ], 2))
}