import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-body" }
const _hoisted_2 = {
  href: "#",
  class: "card-title fw-bolder text-muted text-hover-primary fs-4"
}
const _hoisted_3 = { class: "fw-bolder text-primary my-6" }
const _hoisted_4 = { class: "text-dark-75 fw-bold fs-5 m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: [_ctx.widgetClasses, "card bgi-no-repeat"],
    style: [`background-image: url('media/svg/shapes/${_ctx.background}')`, {"background-position":"right top","background-size":"30% auto"}]
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("a", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createVNode("div", _hoisted_3, _toDisplayString(_ctx.time), 1),
      _createVNode("p", _hoisted_4, [
        _createVNode("span", { innerHTML: _ctx.description }, null, 8, ["innerHTML"])
      ])
    ])
  ], 6))
}